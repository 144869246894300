import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CheckForUpdateService {
    updateSubscription;

    constructor(updates: SwUpdate) {
        console.log('checking for updates', updates.isEnabled);
        if (updates.isEnabled) {
            interval(10 * 60 * 1000).subscribe(() => updates.checkForUpdate().then(() => console.log('checking for updates')));
        }
        updates.versionUpdates.subscribe(evt => {
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    console.log(`Current app version: ${evt.currentVersion.hash}`);
                    console.log(`New app version ready for use: ${evt.latestVersion.hash}`);

                    updates.activateUpdate().then(() => document.location.reload());
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });
    }

    public checkForUpdates(): void {}
}
