import { Component, OnInit } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { fromTopAnimation } from '../../../core/animations';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'es-connection-issues-bar',
    templateUrl: './connection-issues-bar.component.html',
    styleUrls: ['./connection-issues-bar.component.scss'],
    animations: [fromTopAnimation],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class ConnectionIssuesBarComponent implements OnInit {
    public online$: Observable<boolean>;
    constructor() {}

    public ngOnInit(): void {
        this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
    }
}
