import { Component, Inject } from '@angular/core';
import { IEnvironment } from '../../../../environments/environment.interface';
import { NgIf } from '@angular/common';

@Component({
    selector: 'es-test-bar',
    templateUrl: './test-bar.component.html',
    styleUrls: ['./test-bar.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class TestBarComponent {
    constructor(@Inject('ENV') public env: IEnvironment) {}
    public ngOnInit(): void {}
}
