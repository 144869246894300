import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/auth/auth.state';
import { catchError } from 'rxjs/operators';
import { Logout } from '../store/auth/auth.actions';
import { Router } from '@angular/router';
import { SaveReturnUrlAfterLogin } from '../store/layout/layout.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private store: Store,
        private router: Router
    ) {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.store.selectSnapshot(AuthState.token);

        let headers = request.headers;
        // .set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.append('accept', 'application/json');
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('X-Requested-With', 'XMLHttpRequest');
        // X-Requested-With: XMLHttpRequest

        if (token) {
            headers = headers.append('X-AUTH-TOKEN', token);
        }

        const AuthRequest = request.clone({ headers: headers });
        return next.handle(AuthRequest).pipe(catchError(e => this.catchError(e)));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private catchError(e: HttpErrorResponse): Observable<HttpEvent<any>> {
        const errorMessage = e.error?.errors?.general;

        if (e.status === 403 && errorMessage === 'Token wygasł') {
            this.store.dispatch(new SaveReturnUrlAfterLogin({ url: this.router.url }));
            this.store.dispatch(new Logout({ navigate: true, sendLogout: false, notify: 'Nastąpiło automatyczne wylogowanie' }));
        }

        return throwError(e);
    }
}
