<div class="unauthorized-wrapper">
    <div class="unauthorized">
        <h2 class="unauthorized__title">Brak dostępu</h2>
        <p class="unauthorized__text">
            Brak dostępu do wybranej podstrony.
            <br />
            Strona została usunięta lub nie posiadasz uprawnień do jej wyświetlania
        </p>

        <div class="unauthorized__btn">
            <a [routerLink]="['/']" class="esc-button btn--primary">Ok, wracam do głównego widoku</a>
        </div>

        <p class="unauthorized__support">
            W razie pytań prosimy o kontakt z zespołem
            <a class="link" target="_blank" href="https://join.slack.com/t/earlystageewsparcie/shared_invite/zt-c6nb4s1v-QyayyfDnIWESTJrdKLuxIQ">eWsparcia</a>
        </p>

        <div>
            <img src="assets/images/unauthorized-image.svg" alt="" />
        </div>
    </div>
</div>
