import { StorageEngine } from '@ngxs/storage-plugin';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomStorageEngine implements StorageEngine {
    get length(): number {
        return localStorage.length + sessionStorage.length;
    }

    public getItem(key: string): any {
        if (key === 'layout.tableSnapshots' || key === 'layout.tabsSnapshots') {
            return sessionStorage.getItem(key);
        } else {
            return localStorage.getItem(key);
        }
    }

    public setItem(key: string, value: any): void {
        if (key === 'layout.tableSnapshots' || key === 'layout.tabsSnapshots') {
            return sessionStorage.setItem(key, value);
        } else {
            return localStorage.setItem(key, value);
        }
    }

    public removeItem(key: string): void {
        if (key === 'layout.tableSnapshots' || key === 'layout.tabsSnapshots') {
            return sessionStorage.removeItem(key);
        } else {
            return localStorage.removeItem(key);
        }
    }

    public clear(): void {
        // Your logic here
        sessionStorage.clear();
        localStorage.clear();
    }

    public key(val: number): string {
        return sessionStorage.key(val) || localStorage.key(val);
        // Your logic here
    }
}
