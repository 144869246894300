<es-test-bar></es-test-bar>
<router-outlet></router-outlet>
<div [@routerTransition]="prepareRoute(outlet)" class="side-router">
    <router-outlet #outlet="outlet" name="menu"></router-outlet>
</div>

<div class="app-loader" *ngIf="loading">
    <div class="loader-icon"></div>
</div>
<es-support-button *ngIf="showSupportButton || !isMobile"></es-support-button>
<es-connection-issues-bar></es-connection-issues-bar>
<esc-toasts-container />
