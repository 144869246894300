<ng-container *ngIf="(isMobile$ | async) && group">
    <div
        class="side-nav"
        [ngStyle]="{
            background: background,
            color: color
        }">
        <div class="side-nav__header" *ngIf="stickyHeader" [ngStyle]="{ background: background }">
            <h2 class="header__title truncate">{{ group.realization?.names?.simple }} {{ group.name }}</h2>
        </div>

        <button
            type="button"
            class="side-nav__toggler"
            (click)="offExpand()"
            [ngStyle]="{
                background: background,
                'box-shadow': stickyHeader ? 'none' : null
            }">
            <es-icon icon="close" [width]="15" [height]="15" [fill]="color"></es-icon>
        </button>

        <div #container class="side-nav__content" data-scroll-lock-scrollable [ngStyle]="{ 'padding-top.px': stickyHeader ? 55 : null }">
            <div class="section navigation">
                <es-navigation [modifiers]="['white']"></es-navigation>
            </div>

            <div class="section section--small" *ngIf="group$ | async as group">
                <div class="rota">
                    <div class="rota__item">
                        <div class="header-schedule">
                            <div class="header-schedule__item header-schedule-item">
                                <div class="header-schedule-item__left">
                                    <p class="header-schedule-item__title">
                                        {{ getDayOfWeek(group.firstDayOfWeek) }}
                                        {{ group.firstDayStartHour }} -
                                        {{ group.firstDayFinishHour }}
                                    </p>
                                    <p class="header-schedule-item__text" *ngIf="group.isFirstDayOnline">Zajęcia online</p>

                                    <ng-container *ngIf="!group.isFirstDayOnline">
                                        <p class="header-schedule-item__text">{{ group.firstDayLocalization?.name }}</p>
                                        <p class="header-schedule-item__text">{{ group.firstDayLocalization?.address }}</p>
                                        <p class="header-schedule-item__text" *ngIf="group.firstDayRoom">
                                            Sala:
                                            <span>{{ group.firstDayRoom?.name }}</span>
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="header-schedule-item__zoom" *ngIf="group.zoomUrl && group.isFirstDayOnline">
                                    <a href="{{ group.zoomUrl }}" target="_blank" [escTooltip]="group.zoomUrl">
                                        <es-icon [width]="35" [height]="35" [icon]="'circle-camera'"></es-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="header-schedule__item header-schedule-item" *ngIf="group.level?.lessonsPerWeek === 2 && group.secondDayOfWeek">
                                <div class="header-schedule-item__left">
                                    <p class="header-schedule-item__title">
                                        {{ getDayOfWeek(group.secondDayOfWeek) }} {{ group.secondDayStartHour }} -
                                        {{ group.secondDayFinishHour }}
                                    </p>
                                    <p class="header-schedule-item__text" *ngIf="group.isSecondDayOnline">Zajęcia online</p>

                                    <ng-container *ngIf="!group.isSecondDayOnline">
                                        <p class="header-schedule-item__text">{{ group.secondDayLocalization?.name }}</p>
                                        <p class="header-schedule-item__text">{{ group.secondDayLocalization?.address }}</p>
                                        <p class="header-schedule-item__text" *ngIf="group.secondDayRoom">
                                            Sala:
                                            <span>{{ group.secondDayRoom?.name }}</span>
                                        </p>
                                    </ng-container>
                                </div>

                                <div class="header-schedule-item__zoom" *ngIf="group.zoomUrl && group.isSecondDayOnline">
                                    <a href="{{ group.zoomUrl }}" target="_blank" [escTooltip]="group.zoomUrl">
                                        <es-icon [width]="35" [height]="35" [icon]="'circle-camera'"></es-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main">
                <div class="header wrapper">
                    <p class="header__text is-medium">
                        Grupa:
                        <span>{{ group.realization?.names?.simple }} {{ group.name }}</span>
                    </p>
                    <p class="header__text">
                        Filia:
                        <span>{{ group?.agency?.name }}</span>
                    </p>
                    <p class="header__text">
                        Lektor:
                        <span>{{ group.lector?.lastName }} {{ group.lector?.firstName }}</span>
                    </p>
                    <p class="header__text" *ngIf="group.substituteLector">
                        Zastępstwo:
                        <span>{{ group.substituteLector?.lastName }} {{ group.substituteLector?.firstName }}</span>
                    </p>

                    <!-- <button (click)="closeClassRegister()" class="header__close group icon-chevron-left">
                        <span>
                            <es-icon icon="chevron-down" [height]="8" [width]="12">
                        </es-icon>
                        </span>
                        <span class="bold">
                            Wyjdź z dziennika
                        </span>

                    </button> -->
                </div>
            </div>

            <div class="section section--small section--highlight">
                <es-profile-nav [showProfile]="false"></es-profile-nav>
            </div>
        </div>
    </div>

    <div class="overlay" (click)="offExpand()"></div>
</ng-container>
