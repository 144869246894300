import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ModalsService } from 'apps/early-stage-office/src/app/utility-modules/modals/services/modals.service';
import { SupportModalComponent } from '../../modals/support-modal/support-modal.component';
import { TooltipDirective } from '@early-stage/components';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'es-support-button',
    templateUrl: './support-button.component.html',
    styleUrls: ['./support-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, TooltipDirective, AsyncPipe],
})
export class SupportButtonComponent {
    @Select(LayoutState.isPageWithFooter) public isPageWithFooter$: Observable<boolean>;

    constructor(private modals: ModalsService) {}

    public openSupportModal(): void {
        this.modals.open(SupportModalComponent, {
            style: 'anchored',
            anchorType: 'right',
            width: 500,
        });
    }
}
