<div class="connection-issues-bar" *ngIf="!(online$ | async)" [@fromTopAnimation]>
    <div class="container">
        <div class="connection-issues-bar__content">
            <div class="connection-issues-bar__icon">
                <svg
                    width="23"
                    height="20"
                    viewBox="0 0 22.3 19.4"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2">
                    <path
                        d="M20.5 19.4H1.8c-.6 0-1.2-.3-1.5-.8s-.4-1.2-.1-1.7L8.7 1.4C9.2.5 10.1 0 11.1 0s1.9.5 2.4 1.4L22 16.8c.3.6.3 1.2-.1 1.7s-.8.9-1.4.9zM11.1 1.5c-.5 0-.9.2-1.1.6L1.5 17.5v.2s.1.1.2.1h18.8c.1 0 .2-.1.2-.1s.1-.1 0-.2L12.2 2.1c-.2-.4-.6-.6-1.1-.6z" />
                    <path d="M11.1 13.9c-.4 0-.8-.3-.8-.8V5.6c0-.4.3-.8.8-.8s.8.3.8.8v7.5c0 .4-.4.8-.8.8zm.8 2c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8 0-.4.3-.8.8-.8s.8.4.8.8" />
                </svg>
            </div>
            <div>
                <p>Brak połączenia z serwerem, sprawdź połączenie z internetem</p>
            </div>
        </div>
    </div>
</div>
