import { Routes } from '@angular/router';
import { RedirectPageComponent } from './shared/pages/redirect-page/redirect-page.component';
import { HeaderSideNavComponent } from './shared/components/header/header-side-nav/header-side-nav.component';
import { ClassHeaderSideNavComponent } from './shared/components/header/class-header-side-nav/class-header-side-nav.component';
import { UnauthorizedPageComponent } from './shared/pages/unauthorized-page/unauthorized-page.component';
import { MainPageComponent } from './shared/pages/main-page/main-page.component';
import { AppPageComponent } from './shared/pages/app-page/app-page.component';
import { BasicDataGuard } from './core/authentication/auth-resolver.service';

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: AppPageComponent,
        canActivate: [BasicDataGuard],
        children: [
            {
                path: 'superadmin',
                loadChildren: () => import('./modules/admin/admin-routes').then(m => m.ADMIN_ROUTES),
            },
            {
                path: 'lektor',
                loadChildren: () => import('./modules/lector/lector-routes').then(m => m.LECTOR_ROUTES),
            },
            {
                path: 'franczyza/:id',
                loadChildren: () => import('./modules/franchise/franchise-routes').then(m => m.FRANCHISE_ROUTES),
            },
            {
                path: 'platnosci/:id',
                loadChildren: () => import('./modules/payments/payments-routes').then(m => m.PAYMENTS_ROUTES),
            },
            {
                path: 'metodyk',
                loadChildren: () => import('./modules/dos/dos-routes').then(m => m.DOS_ROUTES),
            },
            {
                path: 'profil',
                loadChildren: () => import('./modules/profile/profile-routes').then(m => m.PROFILE_ROUTES),
            },
            {
                path: 'edziennik/:id',
                loadChildren: () => import('./modules/class-register/class-register-routes').then(m => m.CLASS_REGISTER_ROUTES),
            },
            {
                path: 'logowanie',
                loadChildren: () => import('./modules/login/login-routes').then(m => m.LOGIN_ROUTES),
                data: {
                    forceSupportButton: true,
                },
            },
            {
                path: 'brak-dostepu',
                component: MainPageComponent,
                pathMatch: 'full',
                children: [
                    {
                        path: '',
                        component: UnauthorizedPageComponent,
                    },
                ],
                data: {
                    title: 'Brak dostępu',
                },
            },
            {
                path: '',
                component: RedirectPageComponent,
                pathMatch: 'full',
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },

    {
        path: 'glowne',
        outlet: 'menu',
        component: HeaderSideNavComponent,
        data: {
            animation: 'isLeft',
        },
    },

    {
        path: 'dziennik',
        outlet: 'menu',
        component: ClassHeaderSideNavComponent,
        data: {
            animation: 'isLeft',
        },
    },
];
