import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { ComponentRef, Injectable } from '@angular/core';

interface RouteStates {
    max: number;
    handles: { [handleKey: string]: DetachedRouteHandle };
    handleKeys: string[];
}

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
}

function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return (
        '/' +
        route.pathFromRoot
            .filter(v => v.routeConfig)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(v => v.routeConfig!.path)
            .join('/')
    );
}

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    private routes: { [routePath: string]: RouteStates } = {
        '/project': { max: 1, handles: {}, handleKeys: [] },
        '/project/:id': { max: 5, handles: {}, handleKeys: [] },
    };

    /** Determines if this route (and its subtree) should be detached to be reused later */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return !!this.routes[getConfiguredUrl(route)];
    }

    private getStoreKey(route: ActivatedRouteSnapshot) {
        const baseUrl = getResolvedUrl(route);

        const childrenParts = [];
        let deepestChild = route;
        while (deepestChild.firstChild) {
            deepestChild = deepestChild.firstChild;
            childrenParts.push(deepestChild.url.join('/'));
        }

        return baseUrl + '////' + childrenParts.join('/');
    }

    /**
     * Stores the detached route.
     *
     * Storing a `null` value should erase the previously stored value.
     */
    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        if (route.routeConfig) {
            const config = this.routes[getConfiguredUrl(route)];
            if (config) {
                const storeKey = this.getStoreKey(route);
                if (handle) {
                    if (!config.handles[storeKey]) {
                        if (config.handleKeys.length >= config.max) {
                            const oldestUrl = config.handleKeys[0];
                            config.handleKeys.splice(0, 1);

                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const oldHandle = config.handles[oldestUrl] as { componentRef: ComponentRef<any> };
                            oldHandle.componentRef.destroy();

                            delete config.handles[oldestUrl];
                        }
                        config.handles[storeKey] = handle;
                        config.handleKeys.push(storeKey);
                    }
                } else {
                    // we do not delete old handles on request, as we define when the handle dies
                }
            }
        }
    }

    /** Determines if this route (and its subtree) should be reattached */
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (route.routeConfig) {
            const config = this.routes[getConfiguredUrl(route)];

            if (config) {
                const storeKey = this.getStoreKey(route);
                return !!config.handles[storeKey];
            }
        }

        return false;
    }

    /** Retrieves the previously stored route */
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (route.routeConfig) {
            const config = this.routes[getConfiguredUrl(route)];

            if (config) {
                const storeKey = this.getStoreKey(route);
                return config.handles[storeKey];
            }
        }

        return null;
    }

    /** Determines if `curr` route should be reused */
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (this.checkReuse(future) === true && future.routeConfig === curr.routeConfig) {
            return true;
        }

        return (getResolvedUrl(future) === getResolvedUrl(curr) || this.checkReuse(future)) && future.routeConfig === curr.routeConfig;
    }

    private checkReuse(route: ActivatedRouteSnapshot): boolean {
        if (route.data.reuse === true) {
            return true;
        }

        if (route.children) {
            for (const child of route.children) {
                if (this.checkReuse(child)) {
                    return true;
                }
            }
        }

        return false;
    }
}
