<ng-container *ngIf="isMobile$ | async">
    <div class="side-nav">
        <button type="button" class="side-nav__toggler" (click)="offExpand()">
            <es-icon icon="close" [width]="15" [height]="15"></es-icon>
        </button>

        <div class="side-nav__content" data-scroll-lock-scrollable #container>
            <div>
                <ng-container *ngIf="currentRole$ | async as currentRole">
                    <div class="side-nav__section" *ngIf="currentRole.role === 'ROLE_LECTOR'">
                        <es-groups-badges [emptyGroupsCount]="2" [groupsSource]="groups$"></es-groups-badges>
                    </div>
                </ng-container>

                <ng-container>
                    <div class="side-nav__section">
                        <es-navigation></es-navigation>
                    </div>
                </ng-container>

                <div class="side-nav__section">
                    <es-profile-nav></es-profile-nav>
                </div>
            </div>
            <div class="side-nav__bottom">
                <es-header-profile></es-header-profile>
            </div>
        </div>
    </div>

    <div class="overlay" [@fadeAnimation] (click)="offExpand()"></div>
</ng-container>
