import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClassRegisterState } from 'apps/early-stage-office/src/app/modules/class-register/store/class-register.state';
import { IGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { WithDestroyComponent } from 'apps/early-stage-office/src/app/core/abstract/abstract-with-destroy-component';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { Router } from '@angular/router';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ProfileNavComponent } from '../header-profile/profile-nav/profile-nav.component';
import { TooltipDirective } from '@early-stage/components';
import { NavigationComponent } from '../../navigation/navigation.component';
import { IconComponent } from '../../../../utility-modules/icon/components/icon/icon.component';
import { AsyncPipe, DatePipe, NgIf, NgStyle } from '@angular/common';
import { getPolishShortDayOfWeek } from '../../../../core/functions';

@Component({
    selector: 'es-class-header-side-nav',
    templateUrl: './class-header-side-nav.component.html',
    styleUrls: ['./class-header-side-nav.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, IconComponent, NavigationComponent, TooltipDirective, ProfileNavComponent, AsyncPipe, DatePipe],
})
export class ClassHeaderSideNavComponent extends WithDestroyComponent implements OnInit, OnDestroy {
    public exitUrl: string = '';

    @Select(ClassRegisterState.group) public group$: Observable<IGroup>;
    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    public group: IGroup;
    public background: string = '#000';
    public color: string = '#fff';
    public stickyHeader: boolean = true;

    constructor(
        public router: Router,
        private store: Store
    ) {
        super();
    }

    public ngOnInit() {
        this.group$.pipe(takeUntil(this.destroy$)).subscribe(group => {
            this.group = group;

            if (this.group) {
                this.background = this.group.color?.background;
            }
        });
        disablePageScroll();

        this.exitUrl = this.store.selectSnapshot(LayoutState.returnUrl) || '/';
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        enablePageScroll();
    }

    public offExpand() {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]);
        });
    }

    public closeClassRegister() {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]).then(() => {
                this.router.navigate([this.exitUrl, { outlets: { menu: null } }]);
            });
        });
    }

    public getDayOfWeek(day: number): string {
        return getPolishShortDayOfWeek(day);
    }
}
