export const CSSBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    hd: 1366,
    whd: 1440,
};

// export async function delay(time: number): Promise<void> {
//     return new Promise(r => {
//         setTimeout(() => {
//             r();
//         }, time);
//     });
// }
