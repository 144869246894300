import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'es-unauthorized-page',
    templateUrl: './unauthorized-page.component.html',
    styleUrls: ['./unauthorized-page.component.scss'],
    standalone: true,
    imports: [RouterLink],
})
export class UnauthorizedPageComponent implements OnInit {
    constructor() {}

    public ngOnInit(): void {}
}
