import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Observable, Subscription } from 'rxjs';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { IProfileRole } from 'apps/early-stage-office/src/app/core/models/profile.interface';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { SetCurrentRole } from 'apps/early-stage-office/src/app/core/store/profile/profile.actions';

@Component({
    template: '',
    standalone: true,
})
export class RedirectPageComponent implements OnInit, OnDestroy {
    @Select(ProfileState.currentRole) public currentRole$: Observable<IProfileRole>;

    public currentRoleSubscription: Subscription;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.currentRoleSubscription = this.currentRole$.subscribe(currentRole => {
            if (!currentRole) {
                return;
            }

            const isActive =
                currentRole.route && (!currentRole.roleFranchises || currentRole.roleFranchises?.some(r => r.status === true || r.status === undefined));

            this.store.dispatch(new Navigate(isActive ? currentRole.route : ['/profil']));
        });

        const isMobile = this.store.selectSnapshot(LayoutState.isMobile);
        const roles = this.store.selectSnapshot(ProfileState.profileRoles);

        if (!roles || roles.length === 0) {
            this.store.dispatch(new Navigate(['/profil']));
            return;
        }

        if (isMobile) {
            const lectorRole = roles.find(r => r.role === 'ROLE_LECTOR');
            if (lectorRole) {
                this.store.dispatch(new SetCurrentRole({ role: lectorRole }));
            }
        }
    }

    public ngOnDestroy(): void {
        this.currentRoleSubscription.unsubscribe();
    }
}
