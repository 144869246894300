<div class="test-bar" *ngIf="env.test">
    <div class="container">
        <div class="group is-small">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_1786_345509)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5999 8.00014C12.1675 8.00032 12.7271 7.86629 13.2331 7.60898C13.739 7.35167 14.1769 6.97837 14.5111 6.51956C14.8453 6.06074 15.0662 5.52941 15.1559 4.96893C15.2456 4.40846 15.2014 3.83472 15.0271 3.29454C14.9431 3.03534 14.6191 2.98174 14.4255 3.17454L12.2719 5.32814C12.2223 5.38022 12.1583 5.41639 12.0881 5.43205C12.0179 5.44772 11.9446 5.44218 11.8775 5.41614C11.2928 5.17227 10.8277 4.70777 10.5831 4.12334C10.557 4.05615 10.5514 3.98273 10.5671 3.91238C10.5828 3.84202 10.619 3.7779 10.6711 3.72814L12.8263 1.57454C13.0183 1.38174 12.9655 1.05694 12.7063 0.972939C12.1474 0.792488 11.5528 0.751539 10.9744 0.853662C10.396 0.955786 9.85133 1.19788 9.38798 1.55883C8.92462 1.91978 8.55662 2.38861 8.31607 2.92445C8.07552 3.46028 7.96973 4.04683 8.00792 4.63294C8.05192 5.33134 7.90552 6.07934 7.36792 6.52734L1.58392 11.3465C1.35179 11.5406 1.16259 11.7808 1.02839 12.0519C0.89418 12.3231 0.817903 12.6192 0.804414 12.9215C0.790924 13.2237 0.840516 13.5254 0.950032 13.8075C1.05955 14.0895 1.22659 14.3456 1.44052 14.5595C1.65445 14.7735 1.91058 14.9405 2.19261 15.05C2.47463 15.1595 2.77637 15.2091 3.07861 15.1957C3.38086 15.1822 3.67698 15.1059 3.94812 14.9717C4.21927 14.8375 4.4595 14.6483 4.65352 14.4161L9.47272 8.63214C9.92072 8.09454 10.6687 7.94814 11.3671 7.99214C11.4439 7.99774 11.5215 8.00014 11.5999 8.00014ZM3.99992 12.8001C3.99992 13.0123 3.91564 13.2158 3.76561 13.3658C3.61558 13.5159 3.4121 13.6001 3.19992 13.6001C2.98775 13.6001 2.78427 13.5159 2.63424 13.3658C2.48421 13.2158 2.39992 13.0123 2.39992 12.8001C2.39992 12.588 2.48421 12.3845 2.63424 12.2345C2.78427 12.0844 2.98775 12.0001 3.19992 12.0001C3.4121 12.0001 3.61558 12.0844 3.76561 12.2345C3.91564 12.3845 3.99992 12.588 3.99992 12.8001Z"
                        fill="white" />
                    <path
                        d="M11.6 9.20002C11.7384 9.20002 11.876 9.19441 12.0112 9.18241L15.0144 12.1856C15.3895 12.5607 15.6002 13.0695 15.6002 13.6C15.6002 14.1305 15.3895 14.6393 15.0144 15.0144C14.6392 15.3895 14.1305 15.6003 13.6 15.6003C13.0695 15.6003 12.5607 15.3895 12.1856 15.0144L8.65757 11.4864L10.3952 9.40082C10.4368 9.35042 10.5128 9.29041 10.6688 9.24401C10.8304 9.19602 11.044 9.17441 11.2904 9.19041C11.3928 9.19681 11.496 9.20002 11.6 9.20002ZM4.79997 3.66881L6.66397 5.53281C6.64594 5.55956 6.62442 5.58378 6.59997 5.60481L5.43997 6.57121L3.66877 4.80001H2.64717C2.57295 4.80001 2.50019 4.77936 2.43703 4.74036C2.37388 4.70137 2.32282 4.64557 2.28957 4.57921L0.92957 1.85762C0.891989 1.78259 0.878952 1.69765 0.892304 1.61481C0.905656 1.53197 0.944721 1.45543 1.00397 1.39602L1.39597 1.00402C1.45529 0.944635 1.53179 0.90542 1.61463 0.891924C1.69748 0.878428 1.78247 0.891334 1.85757 0.928815L4.57917 2.28882C4.64577 2.32219 4.70173 2.3735 4.74074 2.43696C4.77975 2.50042 4.80027 2.57352 4.79997 2.64801V3.66881Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1786_345509">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <p>Wersja testowa systemu Early Stage Office</p>
        </div>
    </div>
</div>
