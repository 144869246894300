import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadRolesIfEmpty, LoadSchoolYears } from '../store/dictionary/dictionary.actions';
import { GetProfile } from '../store/profile/profile.actions';
import { AuthState } from '../store/auth/auth.state';
import { SetIsLoggedIn } from '../store/auth/auth.actions';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class BasicDataGuard {
    constructor(
        private store: Store,
        private auth: AuthenticationService
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const token = this.store.selectSnapshot(AuthState.token);

        if (!token) {
            /*setTimeout(() => {
                this.store.dispatch(new Logout({ navigate: true, sendLogout: false}));
                this.store.dispatch(new SaveReturnUrlAfterLogin({url: state.url}));
            });*/
            return true;
        }

        const isTokenValid = await this.auth.isTokenValid({ apiToken: token }).toPromise();

        this.store.dispatch(new SetIsLoggedIn({ isLoggedIn: isTokenValid }));

        if (isTokenValid) {
            await this.store.dispatch(new LoadRolesIfEmpty()).toPromise();
            await this.store.dispatch(new LoadSchoolYears()).toPromise();
            await this.store.dispatch(new GetProfile()).toPromise();
        } else {
            /*setTimeout(() => {
                this.store.dispatch(new Logout({ navigate: true, sendLogout: false}));
                this.store.dispatch(new SaveReturnUrlAfterLogin({url: state.url}));
            });*/
        }

        return true;
    }
}
